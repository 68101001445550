<template>
  <div class="reserved-area-page calendar-out">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <div class="reserved-area-creator-top-box">
            <div class="reserved-area-creator-top-info-wrapper">
              <div class="reserved-area-creator-top-info d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <div class="reserved-area-creator-top-info-image">
                    <img src="https://via.placeholder.com/24x24" alt="">
                  </div>
                  <p class="reserved-area-creator-top-info-title">Johanna Cunningham</p>
                </div>
                <button type="button" class="reserved-area-creator-top-info-link" @click="openInfoExtras">
                  <eyeIcon/>
                </button>
              </div>
              <div class="reserved-area-creator-top-info-extra-wrapper">
                <div class="reserved-area-creator-top-info-extra">
                  <p><span>Email:</span> johanna@mail.com</p>
                  <p><span>Telefone:</span> 919191919</p>
                </div>
              </div>
            </div>
            <div class="reserved-area-creator-top-time">
              <div class="reserved-area-creator-top-time-item">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="reserved-area-creator-top-time-item-title">Dia</p>
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="reserved-area-creator-top-time-item-value">Sex, 20/11/2020</p>
                    <button type="button" class="reserved-area-creator-top-time-item-btn" @click="toggleCalendarModal">
                      <pencil/>
                    </button>
                  </div>
                </div>
              </div>
              <div class="reserved-area-creator-top-time-item">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="reserved-area-creator-top-time-item-title">Hora</p>
                  <div class="d-flex justify-content-end align-items-center">
                    <p class="reserved-area-creator-top-time-item-value">19h00</p>
                    <button type="button" class="reserved-area-creator-top-time-item-btn" @click="toggleCalendarModal">
                      <pencil/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Descrição</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="text" name="workout-title" validation="required" placeholder="Insira o titulo do treino" label="Titulo Treino" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="textarea" rows="9" name="about" validation="required" placeholder="" label="Sobre" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie" />
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field input-file">
                      <label class="d-flex flex-column">
                        <FormulateInput type="image" name="about-image" validation="mime:image/jpeg,image/png,image/gif" multiple />
                        <div class="input-file-content d-flex justify-content-between align-items-center">
                          <p class="input-file-title">Adicionar Imagem (Max. 3 Imagens)</p>
                          <button type="button" class="input-file-btn">
                            <plusIcon/>
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Vídeo Chamada</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="text" name="workout-title" validation="required" placeholder="Insira o link Zoom ou Google Meet" label="Link Zoom ou Google Meet" />
                    </div>
                    <p class="form-field-info">Link Visivel - 5 min. Antes do Treino</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Equipamento</p>
              <div class="reserved-area-form-group">
                <div class="reserved-area-list-wrapper">
                  <button type="button" class="reserved-area-list-btn d-flex justify-content-between align-items-center" @click="openAddEquipmentModal">
                    <p class="reserved-area-list-btn-title">Adicionar Equipamento</p>
                    <div class="reserved-area-list-btn-icon d-flex justify-content-center align-items-center">
                      <plusIcon/>
                    </div>
                  </button>
                  <div class="reserved-area-list">
                    <div class="reserved-area-workout-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          </div>
                        </div>
                        <button type="button" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                          <trashIcon/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <calendarModal/>
    <addEquipmentModal/>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import plusIcon from "@/components/svg/plus.vue";
import trashIcon from "@/components/svg/trash.vue";
import eyeIcon from "@/components/svg/eye.vue";
import pencil from "@/components/svg/pencil.vue";

import calendarModal from "@/components/modals/calendar-modal-pt.vue";
import addEquipmentModal from "@/components/modals/add-equipment-modal.vue";

export default {
  name: "reserved-area-creator-calendar-out",
  components: {
    reservedAreaMenu,
    plusIcon,
    trashIcon,
    eyeIcon,
    pencil,
    calendarModal,
    addEquipmentModal
  },
  methods: {
    openInfoExtras() {
      let element = document.getElementsByClassName("reserved-area-creator-top-info-wrapper")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    toggleCalendarModal() {
      let element = document.getElementsByClassName("box-calendar-modal-pt")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    openAddEquipmentModal() {
      let element = document.getElementsByClassName("add-equipment-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
  }
}
</script>